import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import Html from "../components/Html"

function article({ pageContext: { article } }) {
  const links = [
    { id: "1", title: "Home", href: "/" },
    { id: "3", title: "All News", href: "/articles" },
  ]

  const { title, html, published } = article
  const formattedDate = new Date(published).toLocaleDateString("en-GB", {
    month: "long",
    year: "numeric",
    day: "numeric",
  })

  return (
    <Layout headerLinks={links}>
      {() => (
        <section className="bg-primary-50 overflow-x-hidden">
          <Seo title="News" description="Interesting articles" />
          <div className="container mx-auto lg:px-20 xl:px-24 py-12 md:py-14 lg:py-18 xl:py-8">
            <h1 className="text-3xl font-semibold tracking-wide text-primary-500">
              {title}
            </h1>
            <h2>Published on: {formattedDate}</h2>
            <div className="text-lg text-gray-500 sm:text-xl mt-4 max-w-2xl lg:mx-auto">
              <Html html={html} />
            </div>
          </div>
        </section>
      )}
    </Layout>
  )
}
export default article
