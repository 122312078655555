import React from "react"

const Html = ({
  html,
  className,
}: {
  html?: string
  className?: string
}): JSX.Element | null => {
  if (!html) return null

  return (
    <div
      className={`html ${className ?? ""}`}
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

export default Html
